import map from 'lodash/map';
import { recipeTypes } from '@ducks/recipe';
import { localizedUnitFromUnitCode } from '@src/utils/helpers/units';
import types from './types';
import initialState from './initialState';

const getPossibleUnits = possibleUnits =>
  map(possibleUnits, (v, k) => ({ unitName: k, kcalPerUnit: v }));

const getKcalPerUnit = (unit, possibleUnits) =>
  getPossibleUnits(possibleUnits).reduce(
    (kcal, possibleUnit) =>
      possibleUnit.unitName === unit ? possibleUnit.kcalPerUnit : kcal,
    0
  );

const uniqueIndex = (items = []) => {
  const ids = items
    .map(x => x.indexInRecipe)
    .filter(idx => typeof idx === 'number');
  return Math.max(...ids, -1) + 1;
};

const recipeIngredientGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_INGREDIENT_IN_GROUP: {
      const {
        ingredientId,
        indexInRecipe,
        name,
        food = {},
        foodstuff = {},
        amount,
        unit,
        alternativeName,
        id,
        kcalPerPortion,
        kcalPerUnit,
        energyDistribution,
        comment
      } = action.payload;

      const element = {
        ingredientId,
        indexInRecipe:
          typeof indexInRecipe === 'number'
            ? indexInRecipe
            : uniqueIndex(state.items),
        foodstuff: {
          foodstuffId: id || food.id || foodstuff.id,
          name: name || foodstuff.name,
          kcal: kcalPerPortion
        },
        foodstuffId: id || food.id || foodstuff.id,
        text: name || foodstuff.name,
        alternativeName,
        kcal: kcalPerPortion,
        kcalPerUnit:
          getKcalPerUnit(unit, foodstuff.possibleUnits) ||
          kcalPerUnit ||
          foodstuff.kcalPerUnit,
        amount,
        fullAmount: `${amount} ${localizedUnitFromUnitCode(unit) || unit}`,
        unit,
        energyDistribution: energyDistribution || foodstuff.energyDistribution,
        comment
      };

      const newItems = [
        ...state.items,
        ...(!state.items[action.groupIndex]
          ? [
              {
                name: action.name,
                ingredients: []
              }
            ]
          : [])
      ];
      newItems[action.groupIndex].ingredients.push(element);

      return {
        ...state,
        items: [...newItems],
        touched: true
      };
    }
    case types.ADD_INGREDIENT_GROUP: {
      return {
        ...state,
        items: [
          ...state.items,
          {
            name: action.name,
            ingredients: []
          }
        ]
      };
    }
    case types.DELETE_INGREDIENT_IN_GROUP: {
      const { ingredientGroupIndex, recipeIndex } = action;
      const newState = { ...state };
      newState.items[ingredientGroupIndex].ingredients.splice(recipeIndex, 1);
      return {
        ...newState
      };
    }
    case types.DELETE_INGREDIENT_GROUP: {
      const newItems = [...state.items];
      newItems.splice(action.ingredientGroupIndex, 1);

      return {
        ...state,
        items: newItems
      };
    }
    case types.REORDER_INGREDIENTS_IN_GROUP: {
      return {
        ...state,
        items: action.items.items
      };
    }
    case types.EDIT_INGREDIENT_GROUP_NAME: {
      const newState = { ...state };
      newState.items[action.ingredientGroupIndex].name = action.newName;
      return {
        ...newState
      };
    }
    case types.EDIT_INGREDIENT_IN_GROUP: {
      const {
        amount,
        unit,
        alternativeName,
        foodstuff = {},
        comment
      } = action.payload;
      const newState = { ...state };
      newState.items[action.ingredientGroupIndex || 0].ingredients[
        action.payload.indexInRecipe
      ] = {
        ...newState.items[action.ingredientGroupIndex || 0].ingredients[
          action.payload.indexInRecipe
        ],
        amount,
        unit,
        alternativeName,
        comment,
        fullAmount: `${amount} ${localizedUnitFromUnitCode(unit) || unit}`,
        kcalPerUnit:
          getKcalPerUnit(unit, foodstuff.possibleUnits) || foodstuff.kcalPerUnit
      };
      return {
        ...newState
      };
    }
    case recipeTypes.RESET_RECIPE: {
      return initialState;
    }
    case recipeTypes.RECIPE_FULFILLED: {
      return {
        ...initialState,
        items: [
          {
            name: null,
            ingredients: []
          }
        ]
      };
    }
    default:
      return state;
  }
};

export default recipeIngredientGroupsReducer;
